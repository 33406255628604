import React from "react";
import { Paper, Button, Box } from "@mui/material";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import Grid from "@mui/material/Grid";


const Slider = () => {
  return (
    <div className="hero_container">
                <img src="img/mainBanner.jpg"alt=""/>
    </div>
  );
};

export default Slider;
