import React from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import WorkIcon from "@mui/icons-material/Work";
import PlaceIcon from "@mui/icons-material/Place";
import Button from "@mui/material/Button";
import Popup from "reactjs-popup";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Helmet } from "react-helmet";

const Career = () => {
  return (
    <div className="media_query">
      <Helmet>
        <title>Career</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="carrer_bannersc">
        <div className="container">
          <Box className="banner_textbox">
            <h3 className="banner_txt">Your Passion Begins here</h3>
          </Box>
        </div>
      </div>
      <div className="career_container">
        <div className="container">
          <Grid>
            <Box sx={{ alignItems: "center" }}>
              <h3 className="homeservices">Explore Oppertunities</h3>
            </Box>
          </Grid>
        </div>
      </div>
      <h4 className="career_content">Send Your CV at info@ubkinfotech.com</h4>
      <div className="career_bg">
        <div className="career_details">
          <div className="container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper className="detail_box">
                  <h6 className="box_header">Android Developer</h6>
                  <Grid container spacing={2} className="carrer_alignment">
                    <Grid item xs={3} style={{ display: "flex" }}>
                      <WorkIcon className="career_icon" />
                      <h6 className="career_font">2 Yrs</h6>
                    </Grid>
                    <Grid item xs={5} style={{ display: "flex" }}>
                      <PlaceIcon className="career_icon" />
                      <h6 className="career_font"> Rajarhat,Kolkata</h6>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex" }}>
                      <CurrencyRupeeIcon className="career_icon" />
                      <h6 className="career_font">Urgent</h6>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    {/* <DescriptionIcon className="career_icon" /> */}
                    <div className="career_box_height">
                      <h6 className="career_font">
                        • Understanding of Web Services; RESTFUL Architecture,
                        JSON with their use in mobile implementations
                      </h6>
                      <h6 className="career_font p-0">
                        • Eagerness to work and improve herself/himself on both
                        mobile platforms.
                      </h6>
                      <h6 className="career_font p-0">
                        • Strong analytical and problem solving skills.
                      </h6>
                    </div>
                  </Grid>
                  <Box className="career_button_position">
                    {/* <Popup
                    trigger={
                      <Button className="upload_btn"> Upload Resume </Button>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <div className="header">
                          <h4 className="popup_header_txt"> </h4>
                          <img
                            className="popup_logo "
                            src="./logo.webp"
                            alt="logo_img"
                          />
                        </div>
                        <div className="content">
                          <div class="container h-100">
                            <h4 className="career_popup_txt">Upload File</h4>
                            <input
                              accept="image/*"
                              className="hidden"
                              id="button-file"
                              type="file"
                            />
                          </div>
                        </div>
                        <div className="actions">
                          <button
                            className="popup_button"
                            onClick={() => {
                              console.log("modal closed ");
                              close();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup> */}
                    <Button className="upload_btn">
                      <a
                        style={{ color: "white" }}
                        href="mailto:ubkinfotech@gmail.com?subject='Hello from Abstract!'&body='Just popped in to say hello'"
                      >
                        Upload Resume
                      </a>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className="detail_box">
                  <h6 className="box_header">React Developer</h6>
                  <Grid container spacing={2} className="carrer_alignment">
                    <Grid item xs={3} style={{ display: "flex" }}>
                      <WorkIcon className="career_icon" />
                      <h6 className="career_font">2 Yrs</h6>
                    </Grid>
                    <Grid item xs={5} style={{ display: "flex" }}>
                      <PlaceIcon className="career_icon" />
                      <h6 className="career_font"> Rajarhat,Kolkata</h6>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex" }}>
                      <CurrencyRupeeIcon className="career_icon" />
                      <h6 className="career_font">Urgent</h6>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    {/* <DescriptionIcon className="career_icon" /> */}
                    <div className="career_box_height">
                      <h6 className="career_font">
                        • Experienced React JS developer with Redux.
                      </h6>
                      <h6 className="career_font p-0">
                        • Extensive experience in JavaScript, JQuery, HTML5,
                        CSS, Bootstrap.
                      </h6>
                      <h6 className="career_font p-0">
                        • Working knowledge on REST.
                      </h6>
                    </div>
                  </Grid>
                  <Box className="career_button_position">
                    {/* <Popup
                      trigger={
                        <Button className="upload_btn"> Upload Resume </Button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header">
                            <h4 className="popup_header_txt"> </h4>
                            <img
                              className="popup_logo "
                              src="./logo.webp"
                              alt="logo_img"
                            />
                          </div>
                          <div className="content">
                            <div class="container h-100">
                              <h4 className="career_popup_txt">Upload File</h4>
                              <input
                                accept="image/*"
                                className="hidden"
                                id="button-file"
                                type="file"
                              />
                            </div>
                          </div>
                          <div className="actions">
                            <button
                              className="popup_button"
                              onClick={() => {
                                console.log("modal closed ");
                                close();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup> */}
                    <Button className="upload_btn">
                      {" "}
                      <a
                        style={{ color: "white" }}
                        href="mailto:ubkinfotech@gmail.com?subject='Hello from Abstract!'&body='Just popped in to say hello'"
                      >
                        Upload Resume
                      </a>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className="detail_box">
                  <h6 className="box_header">PHP Developer</h6>
                  <Grid container spacing={2} className="carrer_alignment">
                    <Grid item xs={3} style={{ display: "flex" }}>
                      <WorkIcon className="career_icon" />
                      <h6 className="career_font">2 Yrs</h6>
                    </Grid>
                    <Grid item xs={5} style={{ display: "flex" }}>
                      <PlaceIcon className="career_icon" />
                      <h6 className="career_font"> Rajarhat,Kolkata</h6>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex" }}>
                      <CurrencyRupeeIcon className="career_icon" />
                      <h6 className="career_font">Urgent</h6>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    {/* <DescriptionIcon className="career_icon" /> */}
                    <div className="career_box_height">
                      <h6 className="career_font">
                        • Core PHP Coding Knowledge.
                      </h6>
                      <h6 className="career_font p-0">
                        • JQuery Knowledge Preferred.
                      </h6>

                      <h6 className="career_font p-0">
                        • Strong analytical and problem solving skills.
                      </h6>
                    </div>
                  </Grid>
                  <Box className="career_button_position">
                    {/* <Popup
                      trigger={
                        <Button className="upload_btn"> Upload Resume </Button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header">
                            <h4 className="popup_header_txt"> </h4>
                            <img
                              className="popup_logo "
                              src="./logo.webp"
                              alt="logo_img"
                            />
                          </div>
                          <div className="content">
                            <div class="container h-100">
                              <h4 className="career_popup_txt">Upload File</h4>
                              <input
                                accept="image/*"
                                className="hidden"
                                id="button-file"
                                type="file"
                              />
                            </div>
                          </div>
                          <div className="actions">
                            <button
                              className="popup_button"
                              onClick={() => {
                                console.log("modal closed ");
                                close();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup> */}
                    <Button className="upload_btn">
                      {" "}
                      <a
                        style={{ color: "white" }}
                        href="mailto:ubkinfotech@gmail.com?subject='Hello from Abstract!'&body='Just popped in to say hello'"
                      >
                        Upload Resume
                      </a>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className="detail_box">
                  <h6 className="box_header">UI UX Designer</h6>
                  <Grid container spacing={2} className="carrer_alignment">
                    <Grid item xs={3} style={{ display: "flex" }}>
                      <WorkIcon className="career_icon" />
                      <h6 className="career_font">2 Yrs</h6>
                    </Grid>
                    <Grid item xs={5} style={{ display: "flex" }}>
                      <PlaceIcon className="career_icon" />
                      <h6 className="career_font"> Rajarhat,Kolkata</h6>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex" }}>
                      <CurrencyRupeeIcon className="career_icon" />
                      <h6 className="career_font">Urgent</h6>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    {/* <DescriptionIcon className="career_icon" /> */}
                    <div className="career_box_height">
                      <h6 className="career_font">
                        • Investigating user experience design requirements for
                        our suite of digital assets.
                      </h6>
                      <h6 className="career_font p-0">
                        • Developing and conceptualizing a comprehensive UIUX
                        design strategy for the brand.
                      </h6>
                      <h6 className="career_font p-0">
                        • Adhering to style standards on typography and graphic
                        design.
                      </h6>
                    </div>
                  </Grid>
                  <Box className="career_button_position">
                    {/* <Popup
                      trigger={
                        <Button className="upload_btn"> Upload Resume </Button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header">
                            <h4 className="popup_header_txt"> </h4>
                            <img
                              className="popup_logo "
                              src="./logo.webp"
                              alt="logo_img"
                            />
                          </div>
                          <div className="content">
                            <div class="container h-100">
                              <h4 className="career_popup_txt">Upload File</h4>
                              <input
                                accept="image/*"
                                className="hidden"
                                id="button-file"
                                type="file"
                              />
                            </div>
                          </div>
                          <div className="actions">
                            <button
                              className="popup_button"
                              onClick={() => {
                                console.log("modal closed ");
                                close();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup> */}
                    <Button className="upload_btn">
                      {" "}
                      <a
                        style={{ color: "white" }}
                        href="mailto:ubkinfotech@gmail.com?subject='Hello from Abstract!'&body='Just popped in to say hello'"
                      >
                        Upload Resume
                      </a>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className="detail_box">
                  <h6 className="box_header">Node Js</h6>
                  <Grid container spacing={2} className="carrer_alignment">
                    <Grid item xs={3} style={{ display: "flex" }}>
                      <WorkIcon className="career_icon" />
                      <h6 className="career_font">2 Yrs</h6>
                    </Grid>
                    <Grid item xs={5} style={{ display: "flex" }}>
                      <PlaceIcon className="career_icon" />
                      <h6 className="career_font"> Rajarhat,Kolkata</h6>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex" }}>
                      <CurrencyRupeeIcon className="career_icon" />
                      <h6 className="career_font">Urgent</h6>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    {/* <DescriptionIcon className="career_icon" /> */}
                    <div className="career_box_height">
                      <h6 className="career_font">
                        • Extensive knowledge of JavaScript, web stacks,
                        libraries, and frameworks.
                      </h6>
                      <h6 className="career_font p-0">
                        • Design and implementation of low-latency,
                        high-availability, and performance applications.
                      </h6>

                      <h6 className="career_font p-0">
                        • Implementation of security and data protection.
                      </h6>
                    </div>
                  </Grid>
                  <Box className="career_button_position">
                    {/* <Popup
                      trigger={
                        <Button className="upload_btn"> Upload Resume </Button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header">
                            <h4 className="popup_header_txt"> </h4>
                            <img
                              className="popup_logo "
                              src="./logo.webp"
                              alt="logo_img"
                            />
                          </div>
                          <div className="content">
                            <div class="container h-100">
                              <h4 className="career_popup_txt">Upload File</h4>
                              <input
                                accept="image/*"
                                className="hidden"
                                id="button-file"
                                type="file"
                              />
                            </div>
                          </div>
                          <div className="actions">
                            <button
                              className="popup_button"
                              onClick={() => {
                                console.log("modal closed ");
                                close();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup> */}
                    <Button className="upload_btn">
                      {" "}
                      <a
                        style={{ color: "white" }}
                        href="mailto:ubkinfotech@gmail.com?subject='Hello from Abstract!'&body='Just popped in to say hello'"
                      >
                        Upload Resume
                      </a>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className="detail_box">
                  <h6 className="box_header">React-Native</h6>
                  <Grid container spacing={2} className="carrer_alignment">
                    <Grid item xs={3} style={{ display: "flex" }}>
                      <WorkIcon className="career_icon" />
                      <h6 className="career_font">2 Yrs</h6>
                    </Grid>
                    <Grid item xs={5} style={{ display: "flex" }}>
                      <PlaceIcon className="career_icon" />
                      <h6 className="career_font"> Rajarhat,Kolkata</h6>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex" }}>
                      <CurrencyRupeeIcon className="career_icon" />
                      <h6 className="career_font">Urgent</h6>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    {/* <DescriptionIcon className="career_icon" /> */}
                    <div className="career_box_height">
                      <h6 className="career_font">
                        • Build app and UI components from prototypes and
                        wireframes.
                      </h6>
                      <h6 className="career_font p-0">
                        • Maintain code and write automated tests to ensure the
                        product is of the highest quality.
                      </h6>
                      <h6 className="career_font p-0">
                        • Diagnose and fix bugs and performance bottlenecks for
                        performance that feels native.
                      </h6>
                    </div>
                  </Grid>
                  <Box className="career_button_position">
                    {/* <Popup
                      trigger={
                        <Button className="upload_btn"> Upload Resume </Button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header">
                            <h4 className="popup_header_txt"> </h4>
                            <img
                              className="popup_logo "
                              src="./logo.webp"
                              alt="logo_img"
                            />
                          </div>
                          <div className="content">
                            <div class="container h-100">
                              <h4 className="career_popup_txt">Upload File</h4>
                              <input
                                accept="image/*"
                                className="hidden"
                                id="button-file"
                                type="file"
                              />
                            </div>
                          </div>
                          <div className="actions">
                            <button
                              className="popup_button"
                              onClick={() => {
                                console.log("modal closed ");
                                close();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup> */}
                    <Button className="upload_btn">
                      {" "}
                      <a
                        style={{ color: "white" }}
                        href="mailto:ubkinfotech@gmail.com?subject='Hello from Abstract!'&body='Just popped in to say hello'"
                      >
                        Upload Resume
                      </a>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Career;
