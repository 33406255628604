import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Popup from "reactjs-popup";

const Consultant = () => {
  return (
    <Box sx={{ width: 1 }} className="consualt_section">
      <div className="consultant_content container">
        <Grid container spacing={2}>
          <Grid item xs={0} md={3.8}  className="consultant_boxmargin">
            <Box className="consultant_textbox">
              <h3 className="consultant_headertext">24*7 Free Consultation</h3>
              <h5 className="about_txt">Feel free to contact us</h5>
              <div className="footer_line"></div>
              <h5 className="box_conatin">
                {" "}
                Our expertise are always available to help you. Feel free to
                contact Us. To get a quote click on the below button.
              </h5>
              {/* <Link href="/#">
                <Button
                  className="consult_btn">
                  Get a free quote now{" "}
                </Button>
              </Link> */}
              <Popup
                trigger={
                  <Button className="consult_btn">
                    {" "}
                    Get a free quote now{" "}
                  </Button>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <div className="header">
                     <h4 className="popup_header_txt"> Quotation</h4>
                     <img className="popup_logo" src="./logo.webp" alt="logo_img" />
                    </div>
                    <div className="content">
                      <div class="container h-100">
                        <div class="row d-flex justify-content-center align-items-center h-100">
                          <Grid item xs={12}>
                            <div class="form-outline mb-2">
                            <label class="form-label" for="Enter_typename">
                                Full name
                              </label>
                              <input
                                type="text"
                                id="Enter_typename"
                                class="form-control form-control-lg"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div class="form-outline mb-2">
                            <label class="form-label" for="Enter_typeEmail">
                                Email
                              </label>
                              <input
                              required
                                type="email"
                                id="Enter_typeEmail"
                                class="form-control form-control-lg"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div class="form-outline mb-2">
                            <label class="form-label" for="Enter_typePassword">
                                Phone Number
                              </label>
                              <input
                              required
                                type="text"
                                id="Enter_typePassword"
                                class="form-control form-control-lg"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div class="form-outline mb-2">
                            <label class="form-label" for="Enter_typeMsg">
                               Any querry ?
                              </label>
                              <textarea
                                type="text"
                                id="Enter_typeMsg"
                                name="Enter_typeMsg"
                                rows="3" cols="50"
                                class="form-control form-control-lg"
                              >

                              </textarea>
                            </div>
                          </Grid>
                        </div>
                      </div>
                    </div>
                    <div className="actions">
                      <button
                        className="popup_button"
                        onClick={() => {
                          console.log("modal closed ");
                          close();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </Popup>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={12} alignSelf="center">
            <Box className="consultant_iconbox">
              <img src="./satisfaction.webp" alt="satisfaction_img" />
              <h6 className="icon_lowertext">Customer Satisfaction</h6>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={12} alignSelf="center">
            <Box className="consultant_iconbox">
              <img src="./project.webp" alt="project_img" />
              <h6 className="icon_lowertext">Completed Project</h6>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={12} alignSelf="center">
            <Box className="consultant_iconbox">
              <img src="./satisfaction.webp" alt="satisfaction_img" />
              <h6 className="icon_lowertext">Running Project</h6>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={12} alignSelf="center">
            <Box className="consultant_iconbox">
              <img src="./experience .webp" alt="experience_img" />
              <h6 className="icon_lowertext">Year Of Experience</h6>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
export default Consultant;
