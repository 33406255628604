import React from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";
import Link from "@mui/material/Link";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const NotFound404 = () => {
  return (
    <div className="media_query">
      <Helmet>
        <title>NotFound404</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="notFound_bannersc">
        <Container>
          <Box className="banner_textbox">
            <h3 className="banner_txt">Error 404</h3>
          </Box>
        </Container>
      </div>
      <div className="Notfound_content">
        <img src="./404.gif" alt="404" className="not_founf_gif" />
      </div>
      <div className="notfound_conatiner">
        <h4 style={{ fontFamily: "sans-serif",paddingLeft:'9px' }}>Oop, that link is broken.</h4>
        <h6 className="services_text">
          We are sorry, but something went wrong
        </h6>
        <Link href="/">
          <Box sx={{ display: "flex" }}>
            <h4 className="notfound_text">Back to Homepage</h4>
            <ArrowRightAltIcon style={{marginTop:'10px'}}/>
          </Box>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound404;
