
import "./App.css";
import "./style/navbar.css";
import "./style/about.css";
import "./style/services.css";
import "./style/career.css";
import "./style/contact.css";
import "./style/app.css";
import "./style/terms.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages/HomeScreen";
import About from "./pages/About"
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import OurService from "./pages/OurService";
import Portfolio from "./pages/Portfolio";
import {Helmet} from "react-helmet";
import AppDevelopment from "./pages/AppDevelopment";
import WebDevelopment from "./pages/WebDevelopment";
import UIUX from "./pages/UIUX";
import MachineLearning from "./pages/MachineLearning";
import Iot from "./pages/Iot";
import SoftwareSolution from "./pages/SoftwareSolution";
import DigitalMarketing from "./pages/DigitalMarketing";
import BrandingSolution from "./pages/BrandingSolution";
import SoftwareTesting from "./pages/SoftwareTesting";
import NotFound404 from "./pages/NotFound404";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Cancellation from "./pages/Cancellation";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/service" component={OurService} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/app" component={AppDevelopment} />
          <Route exact path="/web" component={WebDevelopment} />
          <Route exact path="/design" component={UIUX} />
          <Route exact path="/machine_learning" component={MachineLearning} />
          <Route exact path="/iot" component={Iot} />
          <Route exact path="/software" component={SoftwareSolution} />
          <Route exact path="/marketing" component={DigitalMarketing} />
          <Route exact path="/branding" component={BrandingSolution} />
          <Route exact path="/testing" component={SoftwareTesting} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/cancellation" component={Cancellation} />
          <Route exact path="/404" component={NotFound404} />
        </Switch>
      </Router>
    </div>
  );

}

export default App;
