import React, { useState } from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet";
import Grid from "@mui/material/Grid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import PlaceIcon from "@mui/icons-material/Place";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const Contact = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    number: "",
    help_text: "",
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <div className="media_query">
      <Helmet>
        <title>Career</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="contact_bannersc">
        <div className="container">
          <Box className="banner_textbox">
            <h3 className="banner_txt">Our Contact</h3>
          </Box>
        </div>
      </div>
      <div className="contact_bg">
        <div className="contact_form">
          <div className="conatiner">
            <Grid container>
              <Grid item xs={12} md={4}>
                <Box className="contact_contentbox">
                  <h2 className="contact_white_txt">Get In Touch</h2>
                  <h6
                    className="small_txt"
                    style={{
                      color: "#fff",
                      marginBlockEnd: "20px",
                      marginBlockStart: "20px",
                    }}
                  >
                    We love to here from you, Our friendly team is always here
                    to chat.
                  </h6>
                  <Grid display="flex">
                    <MailOutlineIcon
                      style={{ color: "#ffffff", margin: "10px" }}
                    />
                    <Box>
                      <h6 className="contact_white_txt">Chat with Us</h6>
                      <h6 className="little_txt">
                        Our friendly team is here to help
                      </h6>
                      <h6 className="contact_white_txt">
                        info@ubkinfotech.com
                      </h6>
                    </Box>
                  </Grid>
                  <Grid display="flex">
                    <PlaceIcon style={{ color: "#ffffff", margin: "10px" }} />
                    <Box>
                      <h6 className="contact_white_txt">Office</h6>
                      <h6 className="little_txt">
                        come say hello! @our office HQ
                      </h6>
                      <h6 className="contact_white_txt">
                        Shree Krishna Building 2nd Floor, Naipukur Rajarhat,
                        700135
                      </h6>
                    </Box>
                  </Grid>
                  <Grid display="flex">
                    <LocalPhoneIcon
                      style={{ color: "#ffffff", margin: "10px" }}
                    />
                    <Box>
                      <h6 className="contact_white_txt">Phone</h6>
                      <h6 className="little_txt">Mon-Sat (10am - 7pm)</h6>
                      <h6 className="contact_white_txt">
                        <a href="tel:8617758895" style={{ color: "white" }}>
                          +91 8617758895
                        </a>
                        <br />
                        <a href="tel:6200938310" style={{ color: "white" }}>
                          +91 6200938310
                        </a>
                      </h6>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box className="contact_form_content">
                  <h4 className="conatct_header" style={{ padding: "8px" }}>
                    We can reach any time
                  </h4>
                  <FormControl sx={{ m: 1, width: "" }} variant="outlined">
                    <h6 className="conatct_text_header">Name</h6>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      value={values.name}
                      onChange={handleChange("name")}
                      className="input_field"
                      // aria-describedby="outlined-weight-helper-text"
                    />
                    <h6 className="conatct_text_header">Email</h6>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      value={values.email}
                      onChange={handleChange("email")}
                      className="input_field"
                      // aria-describedby="outlined-weight-helper-text"
                    />
                    <h6 className="conatct_text_header">Phone number</h6>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      value={values.number}
                      onChange={handleChange("number")}
                      className="input_field"
                      // aria-describedby="outlined-weight-helper-text"
                    />
                    <h6 className="conatct_text_header">How can we help ?</h6>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      value={values.help_text}
                      onChange={handleChange("help_text")}
                      className="input_field"
                      // aria-describedby="outlined-weight-helper-text"
                    />
                    <div className="submit_btn">
                      <Button variant="contained" className="conatct_btn">
            
                        <a style={{color: "white"}} href="mailto:ubkinfotech@gmail.com?subject='Hello from Abstract!'&body='Just popped in to say hello'">Send</a>
                      </Button>
                    </div>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
