import React from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <div className="media_query">
      <Helmet>
        <title>Privacy</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="terms_section">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className="terms_container">
                <h3>Privacy Policy</h3>
                <h5>What info do we assemble?</h5>
                <p>We gather data from you right after you request a service or place an
                    order, avail a subscription to our newsletter, reply to our study or write
                    out a form.
                    You may be asked to enter your email address, name, or phone number and
                    other additional information at the time of order or registration on our site.
                    You can, nevertheless, stay in our site anonymously.
                </p>
                <h5>Purpose of making use of your information</h5>
                <p>Each information we gather from you can possibly be utilized in any of the
                    ways below:
                    To individualize your experience
                    (Your data better conveys your individual preference)
                    To advance our website
                    (we frequently attempt to advance our website offerings founded on the
                    data and response we obtain from you)
                    To advance client facility
                    (your info benefits us to respond to your service demands and upkeep
                    requirements more efficiently)
                    To proceed transactions
                    Your data, whether open or classified, will not be marketed, replaced,
                    relocated, or provided to any other firm for any purpose at all, deprived of
                    your permission, other than for the direct reason of transporting the
                    acquired product or service demanded.
                    To send episodic emails for offers and latest updates
                    The email address you incorporate for order dispensation will solely be
                    exercised to send you data and updates relating to your order.
                </p>
                <h5>How do we guard your data?</h5>
                <p>We employ an array of safety measures to preserve the security of your
                    private info when you enter, submit, or provide your private data or place an
                    order.
                    We provide the usage of a protected server. All the provided delicate/credit
                    info is conveyed via SSL (Secure Socket Layer) technology and then
                    encoded into our Payment Gateway provider’s record only to be han dy by
                    those who are sanctioned with superior access privileges to s uch
                    systems, and are essential to retain the data private.
                    Following a transaction, your confidential info (financials, social security
                    numbers, credit cards, etc.) will not be kept on our server s
                </p>
                <p>Modify the term s and conditions at any time without any prior notice.
                    And the continuing use of your website after the modification will convey
                    your acceptance of the changes made.
                </p>
                <h5>Do we use cookies?</h5>
                <p>Yes, Cookies are min or files that a website or its service provider transports
                    to your computer’s hard drive across your We b Browser (Depends on your
                    permission) that allows the system of the site or service provider to identify
                    your browser, detect and recall specific data.
                    If you wish, you can decide to have your PC inform you ev ery time a
                    cookie is being trans mitted, or you can decide to deactivate all cookies via
                    the settings of your browser. Like most websites, if you deactivate the
                    cookies, a few services of ours possibly will not work a ppropriately.
                    Nevertheless, you will still be able to place orders over the telephone or by
                    communicating with the client service section.
                    
                </p>
                <h5>Do we reveal any info t o third-parties?</h5>
                <p>We do not market, sell, or otherwise handover any of you r individually
                    recognizable info to outside parties. However, this does not comprise
                    reliable third-parties who support us in functioning our website, managing
                    our business, or supporting you, as long as tho se parties settle to retain
                    this data private. We may also discharge your information when we consider
                    discharge is suitable to fulfill with the regulation, apply our site guidelines,
                    or defend our or other’s rights, possessions, or security. However, nonindividually distinguishable visitor data may be delivered to other parties for
                    promotion, publicity, or other usages.
                </p>
                <h5>Third-party links</h5>
                <p>Infrequently, at our will, we may comprise or suggest thir d-party services
                    or products on our website. These third-party sites have distinct and
                    individual privacy policies. We, consequently, However, we try to defend
                    the reliability of our site and receive any response about these websites.
                </p>
                <h5>Online Privacy Policy Only</h5>
                <p>This online privacy policy is relevant only to the data assembled
                    through our website and not to the info gathered offline.
                    
                </p>
                <h5>Terms and Conditions</h5>
                <p>Please also go to our Terms and Conditions segment founding the
                    practice, repudiations, and restrictions of obligation administering the
                    usage of our website at example.com
                </p>
                <h5>Your Agreement</h5>
                <p>By using our site, you agree to our website’s privacy policy.</p>
                <div className="terms_footer_box">
                     <h6>UBK INFOTECH PVT LTD</h6> 
                     <div className="terms_footer_txt">
                        <p>68/A JESSORE ROAD NAGAER BAZAR KOLKATA- 700028 </p>
                     </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
