import React from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

const Terms = () => {
  return (
    <div className="media_query">
      <Helmet>
        <title>Terms</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="terms_section">
      <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Box className="terms_container">
                <h3>Terms and Conditions</h3>
                <h4>Terms and Conditions while taking services from UBK INFOTECH PVT LTD</h4>
                <h5>1 Acceptance the Use of UBK INFOTECH PVT LTD Terms and Conditions</h5>
                <p>By availing any service from UBK INFOTECH PVT LTD, you’re acknowledging the
                    terms and conditions provided by us. Any of your access is subject to follow
                    these terms and conditions strictly. You will not avail UBK INFOTECH PVT LTD for a
                    ny purpose which is illegal or forbidden by these terms and conditions . If
                    you’re availing services from UBK INFOTECH PVT LTD, that denotes the
                    agreement with the disclaimers, terms, and conditions provided in this page
                    . If you’re unlikely to this terms and conditions and do not want to accept,
                    you need to instantly stop using our service.
                </p>
                <h5>2 Advice</h5>
                <p>The contents of UBK INFOTECH PVT LTD website do not establish opinion and
                    should not be entirely relied upon in making or refusing any decision that
                    you ma ke. We write as per the current standard. But you can make your
                    decisions.
                </p>
                <h5>3 Change of Use</h5>
                <p>Modify or Eliminate anything from its website at any point of time without
                    any notice. And by accepting these terms and condition you’re confirming
                    that UBK INFOTECH PVT LTD Technology will neither be liable for any changes done
                    in its site, n or it needs to inform about any modification to its website
                </p>
                <p>Modify the term s and conditions at any time without any prior notice.
                    And the continuing use of your website after the modification will convey
                    your acceptance of the changes made.
                </p>
                <h5>5 Copyright</h5>
                <p>Copyright, trademarks and all the further intellectual property permissions
                    in the Website and its content (including the text, design, graphics and all
                    source codes and software linked to the Website) are possessed by or
                    registered to UBK INFOTECH PVT LTD or else handled by UBK INFOTECH PVT LTD as
                    legalized by law.
                     While accessing the UBK INFOTECH PVT LTD website, you agree that you will
                    access the content exclusively for your private, non-commercial practice.
                    None of the content may be transferred, copied, duplicated, broadcasted,
                    saved, traded or distributed without the aforementioned written agreement
                    of the copyright owner. This keeps
                </p>
                <h5>6 Disclaimers and Limitation of Liability</h5>
                <p>The Website is delivered on AS IS and AS AVAILABLE basis without any
                    depiction or advocacy made and deprived of assurance of any kind
                    whether articulated or suggested, counting but not limited to the indicated
                    warranties of suitable quality, condition for a specific reason, non-violation,
                    compatibility, safety, and precision
                </p>
                <p>
                    As allowable by regulation, UBK INFOTECH PVT LTD will not be accountable for any
                    unintended or subsequent damage or harm whatever (including but not
                    limited to loss of prospect, business, information, revenues) arising out of
                    or regarding the Website practice.
                    UBK INFOTECH PVT LTD doesn’t assure that the functionality of the Website will be
                    continuous or free of errors, that flaws will be modified after the
                    post-project support period or that the Website or the server that avails it is
                    virus-free or free of other damaging elements.
                </p>
                <p>
                    Not anything in these Terms and Conditions will be interpreted in order to
                    discard or limit the accountability of UBK INFOTECH PVT LTD for death or individual
                    damage as a result of the carelessness of UBK INFOTECH PVT LTD or that of its
                    staffs or managers.
                </p>
                <h5>7 Indemnity</h5>
                <p>You agree to ensure, and hold UBK INFOTECH PVT LTD and its personnel and
                    managers safe from and against all obligations, legal charges,
                    compensations, debits, charges and other expenditures concerning any
                    allegation or action brought UBK INFOTECH PVT LTD arising out of any violation by
                    you of these Terms and Conditions or other obligations a rising out of your
                    use of UBK INFOTECH PVT LTD Website.
                </p>
                <h5>8 Compensation</h5>
                <p>If any of these Terms and Conditions is verified by any court of competent
                    jurisdiction to be unacceptable, unlawful or unenforceable for any cause,
                    then the Terms or Condition will certainly b e cut off and the outstanding Term
                    s and Conditions will continue and stay in full force and result and remain to
                    be enforceable and compulsory.
                </p>
                <h5>9 Governing Law</h5>
                <p>These Terms and Conditions written on UBK INFOTECH PVT LTD will be administered
                    by and interpreted in line with the law of India and you hereby submit to the
                    complete authority of the Indian courts.
                </p>
                <div className="terms_footer_box">
                    <h6>UBK INFOTECH PVT LTD</h6>
                    <div className="terms_footer_txt">
                        <p>68/A JESSORE ROAD NAGAER BAZAR KOLKATA- 700028</p>
                    </div>
                </div>
            </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
