import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import Grid from "@mui/material/Grid";

export default function Topheader() {
  const handleClick =(e)=>{
    console.log(e)
  }
  return (
    <section className="top_header" id="myHeader">
      <div className="">
      <Grid container spacing={2}>
        <Grid item xs={6} sm={8} md={6}>
          <Box sx={{ display: "flex" }} ml={10}>
            <Box sx={{ display: { xs: "flex", md: "flex" } }} className="topheader_txt">
              <LocalPhoneIcon />
              <Typography component="div" sx={{ flexGrow: 1 }}>
                <Box
                  sx={{ display: { xs: "none", md: "flex" } }}
                  color="inherit"
                  underline="none"
                >
                 <button onClick={handleClick} style={{background:'transparent',border:'none'}}><a href="tel:6200938310"><h6>+91 6200938310</h6></a> </button> 
                 <h6>/</h6>
                 <button onClick={handleClick} style={{background:'transparent',border:'none'}}><a href="tel:8617758895"><h6>+91 8617758895</h6></a></button>
                </Box>
              </Typography>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "flex" } }} pl={2} className="topheader_txt">
              <EmailIcon />
              <Typography component="div" sx={{ flexGrow: 1 }}>
                <Box
                  color="inherit"
                  sx={{ display: { xs: "none", md: "flex" } }}
                  underline="none"
                >
                  <a href="mailto:info@ubkinfotech.com"><h6>info@ubkinfotech.com</h6></a>
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={6}>
          <Box sx={{ display: "flex" }} className="topheader_txt justify_tophead">
            
            <Typography component="div" pl={1} className="topheader_txt">
              <Link target="_blank" href="https://www.facebook.com/UBKINFO/" color="inherit" underline="none">
                <FacebookIcon />
              </Link>
            </Typography>
            <Typography component="div" pl={1} className="topheader_txt">
              <Link target="_blank" href="https://www.instagram.com/ubkinfotech/" color="inherit" underline="none">
                <InstagramIcon />
              </Link>
            </Typography>

            <Typography component="div" pl={1} className="topheader_txt">
              <Link target="_blank" href="https://www.linkedin.com/company/ubkinfotech/" color="inherit" underline="none">
                <LinkedInIcon />
              </Link>
            </Typography>

            <Typography component="div" pl={1} className="topheader_txt">
              <Link target="_blank" href="https://wa.me/+916200938310" color="inherit" underline="none">         
                <WhatsAppIcon />
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      </div>
    </section>


  );
}
