import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

const AboutUs = () => {
  return (
    <div className="aboutus_section">
      
      <Card
        sx={{ display: { xs: "block", md: "flex" ,sm:'flex'} }}
        className="aboutus_container"
      >
        <Grid container spacing={2}>
          <Grid item xs={0} md={6} sm={6}>
            <img className="about_img" alt="man_img" src="./man-12326.png" />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box className="aboutus_text" xs={12} md={8} sm={6}>
              <h2 sx={{ fontSize: 14 }} className="aboutus_header">
                Who we are
              </h2>
              {/* <div className="underline"></div> */}
              <h6 className="aboutus_details">
                We are a team of IT professionals with 7 years of combined
                experience. We deliver effective IT solutions for web design and
                development, graphics and logos, banners and brochures,
                E-commerce, and digital marketing. At Ipsa Technologies,
                customer satisfaction is paramount. Ours is a startup committed
                to providing clients with transparent and qualitative services
                on time, every time. We work towards meeting business
                requirements and goals without cutting corners or denting your
                wallet. Because we are capable of delivering requirement based
                projects along with professional support and transparent
                pricing, we believe we are the best company for your business
                needs. Choose us for our experience, expertise, and efficiency.
              </h6>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default AboutUs;
