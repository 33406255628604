import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

const Services = () => {
  return (
    <div className="service_bg">
      <div className="sub_container">
        <div className="container">
          <Grid item xs={12}>
            <Box className="sevice_header_txt">
              <h3 className="homeservices ">Our Services</h3>
            </Box>
          </Grid>

          <Box sx={{ display: { xs: "block", md: "flex", sm: "flex" } }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/app">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img
                          className="icon"
                          src="./app development.webp"
                          alt="app_img"
                        />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt">App Development</h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                    The contribution of mobile apps in this modern era, from
                    personal usage to business perspective, cannot be
                    overstated. In fact, mobile apps have become the go-to
                    platform for industries looking to increase conversion and
                    ROI.For the most effective development of modern and
                    visually appealing mobile apps, UBK INFOTECH PVT LTD is one such
                    well-known and tech-stack mobile app development company in
                    Kolkata.
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/web">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img
                          className="icon"
                          src="./web development.webp"
                          alt="web_img"
                        />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt">web development</h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                    We need to know everything about your company when creating
                    a custom website design for you. We don't write a single
                    line of code until we've mocked up the ideal layout and
                    structure that both of us agree, this ensures you'll love
                    your new website when it's complete.UBK INFOTECH PVT LTD is one such
                    well-known and tech-stack web development company in
                    Kolkata.
                  </p>
                </Box>
              </Grid>

              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/design">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img className="icon" src="./uiux.webp" alt="pos_img" />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt"> UI/UX Design</h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                    If you want to keep your visitors on your website or app and
                    keep them coming back for more, it's time to think outside
                    the box. Our talented designers are experts at delivering
                    out-of-the-box solutions that ensure the achievement of your
                    goal. We strive to create designs for all humans that are
                    logical, intuitive, and visually appealing.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "inline-flex",
              display: { xs: "block", md: "flex", sm: "flex" },
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/machine_learning">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img
                          className="icon"
                          src="./machine learing.webp"
                          alt="seo_img"
                        />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt">
                          Machine Learning
                        </h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                    Create adaptive models for object classification and
                    predictive analytics directly from complicated datasets,
                    such as determining which new advertising markets to enter.
                    UBK INFOTECH PVT LTD provides machine learning solution in Kolkata
                    using Python from data scientists. Full-scale project
                    execution and tool development in Data Analytics, Machine
                    Learning, and Deep Learning. Scale Up your bussiness using
                    Machine Learning
                  </p>
                </Box>
              </Grid>

              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/iot">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img className="icon" src="./iot.webp" alt="seo_img" />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt">iot</h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                    IoT development entails mixing hardware components and
                    software programmes so that the finished product may monitor
                    certain values, gather and send data, evaluate given data,
                    and cause the physical device to act accordingly. Developing
                    such systems is a significant problem.UBK INFOTECH PVT LTD is an IoT
                    development company that helps new businesses and
                    enterprises throughout the globe build sophisticated IoT
                    apps .
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/software">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img
                          className="icon"
                          src="./software solution.webp"
                          alt="seo_img"
                        />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt">
                          SOFTWARE SOLUTIONS
                        </h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                  UBK INFOTECH PVT LTD is a software development company that offers
                    industry-specific software solutions to enterprises all
                    around the world. We specialise in providing software
                    development solutions. Net Solutions allows you to create
                    world-class software. Our skilled developers work with your
                    company to increase income and growth. We prioritise your
                    requirements and provide high-quality software solutions
                    based on our experience and skills.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "inline-flex",
              display: { xs: "block", md: "flex", sm: "flex" },
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/marketing">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img
                          className="icon"
                          src="./digital marketing.webp"
                          alt="application_img"
                        />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt">
                          Digital Marketing
                        </h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                    We aim to produce beneficial leads that will take your
                    company to new heights. Every time, we go above and above to
                    make you a dominant brand. You can fully focus on your main
                    company with a result-driven digital marketing approach. Let
                    us work together to bring about digital change. Use the
                    power of digital marketing to achieve your goals.
                  </p>
                  {/* <h6 className="hover_txt">04</h6> */}
                </Box>
              </Grid>

              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/branding">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img
                          className="icon"
                          src="./branding solution.webp"
                          alt="graphics_img"
                        />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt">
                          Branding Solution
                        </h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                    Our corporate branding solutions create a distinct identity
                    for your brand, giving you a competitive advantage. Imagine
                    having access to the world's most powerful and valuable
                    brands' secret brand strategy for generating long-term
                    profitable growth.UBK INFOTECH PVT LTD has the branding and
                    marketing knowledge you require to be successful.We are one
                    of the best creative advertising and branding agencies in
                    Kolkata
                  </p>
                </Box>
              </Grid>

              <Grid item xs={12} md={4} sm={4}>
                <Box className="service_content_box">
                  <Link href="/testing">
                    <Box sx={{ display: "flex" }}>
                      <Box className="icon_box">
                        <img
                          className="icon"
                          src="./software testing.webp"
                          alt="seo_img"
                        />
                      </Box>
                      <Box>
                        <h5 className="app_development_txt">
                          SOFTWARE TESTING
                        </h5>
                        {/* <div className="text_box"></div> */}
                      </Box>
                    </Box>
                  </Link>
                  <p className="app_development_paragrah small_font">
                    Get software testing services in Kolkata because we deliver
                    custom, results-driven campaigns that help our clients.
                    Full-cycle testing services. QA Managed; QA consulting ;
                    Systems & platforms. CRM testing; IoT testing ; Software
                    lifecycle. QA Managed; QA Test automation . Having 7 years
                    of expertise in offering software testing services, we seek
                    to deliver high-quality testing solutions to strive for a
                    marked digital experience.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Services;
