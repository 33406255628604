import React from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Slider from "../components/Slider";
import Topheader from "../components/Topheader";
import Services from "../components/Services";
import AboutUs from "../components/AboutUs";
import TechnologyFrameworks from "../components/technologyName/TechnologyFrameworks";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Technologies from "../components/Technologies";
import Footer from "../components/Footer";
import Consultant from "../components/Consultant";
import Review from "../components/Review";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <div className="media_query">
      <Helmet>
        <title>UBK Infotech</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
      </Helmet>
      <Topheader />

      <ResponsiveAppBar />

      <Slider />

      <Services />

      <AboutUs />

      <Technologies />

      <TechnologyFrameworks />

      <Consultant />

      <Review />

      <div className="faq_bg">
        <div className="faq_container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="  ">
                <img
                  className="w-90 pt-4"
                  src="./faq.png"
                  alt="price_img_img"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="faq_text_container">
                <h2 className="bold_header center large">WHY CHOOSE US?</h2>
                <h6 className="faq_text">
                  At UBK INFOTECH PVT LTD, we keep customer satisfaction at the
                  center. With our affordable and reliable services, you
                  wouldn't have to go looking anywhere else!
                </h6>
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel2a-header"
                    >
                      <Typography>Why An FAQ Resource?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        We have built all our infrastructure on most popular and
                        reliable cloud providers such as Amazon AWS, Google
                        Cloud, Microsoft Azure, Linode and many more! So, expect
                        top-notch performance that you won't get at other
                        "cheap" hosting provider.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Why FAQ Pages Are A Priority ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        We work harder to support your online projects 24x7. Our
                        support heroes are available 24x7 online to help you
                        with any problem you face with the hosting service.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="box_shadow">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>What should a FAQ contain?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        From Domains to hosting we have got you covered with
                        full range of service that your business will need to go
                        online.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>What can I use instead of FAQ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <Footer />
    </div>
  );
}
