import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";

const steps = [
  {
    label: "Select campaign settings",
    description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: "Create an ad group",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Create an ad",
    description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
  },
];

const Review = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="">
      <div className="client_bg">
        <div className="client_sub_container">
          <Grid item xs={12}>
            <Box className="sevice_header_txt">
              <h3 className="homeservices">Take a look Our Clients</h3>
            </Box>
          </Grid>
          <div className="container">
            <Carousel>
              <Carousel.Item interval={1000}>
                <Grid container >
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./ozon.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./ozon.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                </Grid>
              </Carousel.Item>
              <Carousel.Item interval={500}>
                <Grid container >
                <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./ozon.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./ozon.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./ozon.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./ozon.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./ozon.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./ozon.webp"
                      alt="client_img"
                    />
                  </Grid>
                  
                </Grid>
              </Carousel.Item>
              <Carousel.Item>
                <Grid container>
                <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <img
                      className="client_img"
                      src="./moters1.webp"
                      alt="client_img"
                    />
                  </Grid>
                  
                </Grid>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="review_content">
        <Paper elevation={0}>
          <Grid container className="customer_content">
            <Grid item xs={12} md={4}>
              <h3 className="review_hedaertext">
                What Our <br /> Customer says
              </h3>
            </Grid>
            <Grid item xs={12} md={8} alignSelf="center">
              <Box sx={{ width: "95%" }}>
                {/* <FormatQuoteIcon className="icon_size" /> */}
                <Box
                  sx={{
                    height: "auto",
                    width: "90%",
                    p: 2,
                    fontFamily: "sans-serif",
                  }}
                >
                  {steps[activeStep].description}
                </Box>
                <MobileStepper
                  variant="text"
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  style={{ background: "transparent" }}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      Next
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      Back
                    </Button>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default Review;
