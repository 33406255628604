import React from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

const Cancellation = () => {
  return (
    <div className="media_query">
      <Helmet>
        <title>Cancellation</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="terms_section">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className="terms_container">
                <h3>Cancellation and Refund</h3>
                <h4>
                  Our refund policy has been planned to describe the
                  circumstances under which will deliver a refund. The process
                  for demanding a refund and the accountability of UBK INFOTECH PVT LTD
                  result in such an appeal. Registration for any of ou r
                  facilities states that you approve and accept all the terms
                  and conditions summarized in the re fund strategy.
                </h4>
                <h5>Coverage & Scope</h5>
                <p>
                  Our refund policy involves the refunds by UBK INFOTECH PVT LTD and/or
                  a website possessed and handled by UBK INFOTECH PVT LTD – a creative
                  web design company. This policy does not possess any claim for
                  businesses which are not preserved or managed by UBK INFOTECH PVT LTD
                  or for people not working or administered by UBK INFOTECH PVT LTD.
                  This involves a ny external service and/or product suppliers
                  bound by the agreement and also, any external website to which
                  UBK INFOTECH PVT LTD’s website link.
                </p>
                <h5>Filing a Complaint</h5>
                <p>
                  At UBK INFOTECH PVT LTD , a creative website and app development
                  design and digital marketing company, we consider each project
                  with the ultimate product in vision. It is as much our duty as
                  the client’s. So, we think that each attempt should be made to
                  reach a solution that is fully acceptable reciprocally in case
                  of any situation where dissatisfaction related to services
                  comes. Only when things are completely out of hand that refund
                  should be considered. Our final aim is to come to a mutually
                  acceptable solution. Even then if for some reason you are not
                  satisfied and th ink about going for a refund claim, it is our
                  request that you take out a few precious minutes to write to
                  us at info@ubkinfotech.com for a final dialogue prior to
                  requesting a refund. Only if we are unable to reach a common
                  ground with you after talks should a refund claim be filed?
                </p>
                <h5>Eligibility for refund</h5>
                <p>
                  At UBK INFOTECH PVT LTD, a creative web development, app development ,
                  digital marketing, and web development company, every project
                  is important to us and we make sure that we handle each
                  project with utmost care and professionalism. Our aim is to
                  provide the results as per the terms and conditions of the
                  proposal. However, despite every measure, if the client is not
                  satisfied with the results and chooses to go for the dispute
                  resolution process, we consider refund requests as per the
                  following program in which our services and refund policy
                  related to each service are very clearly listed:
                </p>
                <h5>Delivery Time</h5>
                <p>
                  Full refund: In cases where the project has not been started
                  or if the initial design style has not been approved. The Full
                  Refund policy will initiate after receive the upfront payment
                  date and will take 180 Days to refund the amount. Partial
                  refund: If there is a failure to deliver as per our delivery
                  policy after the approval of the initial design style. T he
                  partial refund will be i n proportion to the work completed.
                  No refund: For the time that the services have already been
                  provided. If the client is not satisfied with any level of
                  work, he must immediately bring it to the notice of our
                  project manager and ask for an y discounts for the work loss.
                  Any negotiations at a later date will not be entertained.
                </p>
                <h5>Logo design/brochure design</h5>
                <p>
                  Full refund: In cases where the project has not been started
                  or if the initial design style has not been approved. Partial
                  refund: If there is a failure to deliver as per our delivery
                  policy after the approval of the initial design style. T he
                  partial refund will be i n proportion to the work completed.
                  No refunds: If the project has been completed and uploaded on
                  the server, or the claim made by client seem to be irrelevant
                </p>
                <h5>Web programming</h5>
                <p>
                  Full refund: If the project has not been initiated. There is
                  an agreement for every web programming project. However, if
                  there is no agreement and no clear discussion of the refund
                  policy, the following delivery policy will hold true. A
                  partial refund will be issued as per the judgment of the
                  service provider if we f ail to complete the project in
                  accordance with the delivery policy and contract of agreement.
                  The amount will be calculated by UBK INFOTECH PVT LTD taking into
                  account the proportion of t he project completed and the
                  proportion yet to be completed as per the pre-defined scope of
                  the projec
                </p>
                <h5>NOTE: UBK INFOTECH PVT LTD delivery commitment is subject to:</h5>
                <p>
                  Full refund: If the project has not been initiated by the
                  programmer/designer/content writer/SEO expert or any other
                  resource and/ or staff. Proportionate refund: In accordance
                  with the amount of work done till the time client decides to
                  cancel the order. No refund: For the time that the services
                  have already been provided. If the client is not satisfied
                  with any level of work, he must immediately bring it to the
                  notice of our project manager and ask for an y discounts for
                  the work loss. Any negotiations at a later date will not be
                  entertained.
                </p>
                <h5>NOTE: UBK INFOTECH PVT LTD delivery commitment is subject to:</h5>
                <p>
                  A project is not taken to be void unless the agreed upon
                  payments are clear. The refund policy is not applicable if the
                  required in formation for the successful completion of the
                  project is not given to us at a proper time. If t here is
                  delay or failure in the completion of the
                </p>
                <div className="terms_footer_box">
                  <h6>UBK INFOTECH PVT LTD</h6>
                  <div className="terms_footer_txt">
                    <p>68/A JESSORE ROAD NAGAER BAZAR KOLKATA- 700028 </p>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Cancellation;
