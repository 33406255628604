import "./tabs.css";
import Tabs from "./Tabs";
import { Container } from "react-bootstrap";

export default function TechnologyFrameworks() {
  const tech = [
    {
      id: 0,
      name: "Frontend",
      data: [
        {
          name: "React Js",
          img: "./img/reactjs.png",
        },
        {
          name: "Angular",
          img: "./img/angular.png",
        },
        {
          name: "HTML",
          img: "./img/html.png",
        },
        {
          name: "CSS",
          img: "./img/css.png",
        },
        {
          name: "Bootstrap",
          img: "./img/bootstrap.png",
        },
        {
          name: "Sass",
          img: "./img/saas.png",
        },
        // {
        //   name: "D3 js",
        //   img: "./img/d3js.png",
        // },
      ],
    },
    {
      id: 1,
      name: "Backend",
      data: [
        {
          name: "PHP",
          img: "./img/php.png",
        },
        {
          name: "Node Js",
          img: "./img/nodejs.png",
        },
        {
          name: "Laravel",
          img: "./img/lavarel.png",
        },
        {
          name: "Codeigniter",
          img: "./img/codeignitor.png",
        },
        {
          name: "Python",
          img: "./img/python.png",
        },
      ],
    },
    {
      id: 2,
      name: "Mobile App",
      data: [
        {
          name: "Java",
          img: "./img/java.png",
        },
        {
          name: "kotlin",
          img: "./img/kotline.png",
        },
        {
          name: "React Native",
          img: "./img/reactjs.png",
        },
        {
          name: "Flutter",
          img: "./img/flutter.png",
        },
        {
          name: "Swift",
          img: "./img/swift.png",
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      data: [
        {
          name: "MySQL",
          img: "./img/mysql.png",
        },
        {
          name: "MongoDB",
          img: "./img/mongodb.webp",
        },
        {
          name: "PgSQL",
          img: "./img/postgrey.png",
        },
        {
          name: "SQLite",
          img: "./img/sqlite.png",
        },
        {
          name: "Firebase",
          img: "./img/firebase.png",
        },
      ],
    },
  ];
  return (
    <div className={`wrapper`}>
      <Container className={`nav-container`}>
        <Tabs tech={tech} />{" "}
      </Container>
    </div>
  );
}
