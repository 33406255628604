import React from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import Footer from "../components/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Popup from "reactjs-popup";
import Stepper from "../components/Stepper";
import data from "../components/card/data.json";
import Card from "../components/card/Card";

const WebDevelopment = () => {
  return (
    <div className="media_query">
      <Helmet>
        <title>WebDevelopment</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="app_bannersc">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sm={6}>
              <Box className="appbanner_textbox">
                <h3 className="appbanner_headertext">
                  Best Website Development Company In Kolkata
                </h3>
                <h6 className="app_smalltext">
                  Let your Bussiness vision Talke Shape with the Asistance of
                  our App Development and Top-Grade technocrats.
                </h6>
                <div className="appcontact_btn">
                  <Popup
                    trigger={
                      <Button className="gradient_btn">
                        {" "}
                        CONSULT WITH OUR APP EXPERT
                      </Button>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <div className="header">
                          <h4 className="popup_header_txt"> Quotation</h4>
                          <img
                            className="popup_logo"
                            src="./logo.webp"
                            alt="logo_img"
                          />
                        </div>
                        <div className="content">
                          <div class="container h-100">
                            <div class="row d-flex justify-content-center align-items-center h-100">
                              <Grid item xs={12}>
                                <div class="form-outline mb-2">
                                  <label
                                    class="form-label"
                                    for="Enter_typename"
                                  >
                                    Full name
                                  </label>
                                  <input
                                    type="text"
                                    id="Enter_typename"
                                    class="form-control form-control-lg"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div class="form-outline mb-2">
                                  <label
                                    class="form-label"
                                    for="Enter_typeEmail"
                                  >
                                    Email
                                  </label>
                                  <input
                                    required
                                    type="email"
                                    id="Enter_typeEmail"
                                    class="form-control form-control-lg"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div class="form-outline mb-2">
                                  <label
                                    class="form-label"
                                    for="Enter_typePassword"
                                  >
                                    Phone Number
                                  </label>
                                  <input
                                    required
                                    type="text"
                                    id="Enter_typePassword"
                                    class="form-control form-control-lg"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div class="form-outline mb-2">
                                  <label class="form-label" for="Enter_typeMsg">
                                    Any querry ?
                                  </label>
                                  <textarea
                                    type="text"
                                    id="Enter_typeMsg"
                                    name="Enter_typeMsg"
                                    rows="3"
                                    cols="50"
                                    class="form-control form-control-lg"
                                  ></textarea>
                                </div>
                              </Grid>
                            </div>
                          </div>
                        </div>
                        <div className="actions">
                          <button
                            className="popup_button"
                            onClick={() => {
                              console.log("modal closed ");
                              close();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <Box sx={{ textAlign: "end" }}>
                <img
                  className="app_banner_img"
                  src="./hire_img.webp"
                  alt="interview_img"
                />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <div className="app_bg"> */}
      <div className="service_bg">
        <div className="app_detail_container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box className="detail_img_box">
                <img
                  className="app_image"
                  src="./web_icon.webp"
                  alt="interview_img"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box>
                <h5 className="bold_header">
                  Customized Web Development Service In Kolkata
                </h5>
                <h6 className="app_smallfont">
                  When we are creating a custom website design for you, we have
                  to know everything about your business. We don’t start
                  building a single line of code until we’ve mocked up the
                  perfect layout and structure that we both agree on. Once you
                  approve the layout, that’s when we roll up our sleeves and
                  start the construction – this ensures you’ll love your new
                  website when it’s complete.
                </h6>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="app_sub_container margin_bttm">
        <h2 className="bold_header center large">
          Website Development
        </h2>

      
        <Grid
          container
          spacing={2}
          style={{ display: "flex" }}
        >
          {data.web.map((user) => (
            <Grid item xs={12} md={6} sm={6}>
              <Box sx={{ display: "flex" }}>
                <Card name={user} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="facility_bg">
        <div className="facility_container">
          <div className="container">
            <Grid container spacing={4} style={{ textAlign: "-webkit-center" }}>
              <Grid item xs={12} md={3.8} sm={4}>
                <Box className="facility_content_box">
                  <img className="icon" src="./stack_team.webp" alt="app_img" />
                  <h6 className="app_facility_txt">Fully Customizable</h6>
                </Box>
              </Grid>
              <Grid item xs={12} md={3.8} sm={4}>
                <Box className="facility_content_box">
                  <img
                    className="icon"
                    src="./stack_team.webp"
                    alt="delivery_img"
                  />
                  <h6 className="app_facility_txt">100% Mobile Responsive</h6>
                </Box>
              </Grid>
              <Grid item xs={12} md={3.8} sm={4}>
                <Box className="facility_content_box">
                  <img
                    className="icon"
                    src="./price_img.webp"
                    alt="price_img_img"
                  />
                  <h6 className="app_facility_txt">SEO Friendly Website</h6>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="app_sub_container m-0 web_responsive">
        <h2 className="bold_header center large">How it works</h2>
        {/* <Grid container spacing={1} justifyContent={"center"}>
          <Grid item xs={12} md={2} sm={3}>
            <Box className="facility_content_box">
              <h6 className="app_facility_txt">Step-1</h6>
              <img className="icon" src="./price_img.webp" alt="step_1_img" />
              <h6 className="app_facility_txt">We meet you</h6>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={3}>
            <Box className="facility_content_box ">
              <h6 className="app_facility_txt">Step-2</h6>
              <img className="icon" src="./price_img.webp" alt="step_1_img" />
              <h6 className="app_facility_txt">Elicit your requirements</h6>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={3}>
            <Box className="facility_content_box ">
              <h6 className="app_facility_txt">Step-3</h6>
              <img className="icon" src="./price_img.webp" alt="step_1_img" />
              <h6 className="app_facility_txt">Build Prototype</h6>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={3}>
            <Box className="facility_content_box ">
              <h6 className="app_facility_txt">Step-4</h6>
              <img className="icon" src="./price_img.webp" alt="step_1_img" />
              <h6 className="app_facility_txt">Development on approval</h6>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={3}>
            <Box className="facility_content_box ">
              <h6 className="app_facility_txt">Step-5</h6>
              <img className="icon" src="./price_img.webp" alt="step_1_img" />
              <h6 className="app_facility_txt">Finally it will go live</h6>
            </Box>
          </Grid>
        </Grid> */}
        <Stepper />
      </div>
      <div className="faq_bg">
        <div className="faq_container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="  ">
                <img
                  className="w-90 pt-4"
                  src="./faq.png"
                  alt="price_img_img"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="faq_text_container">
                <h2 className="bold_header center large">WHY CHOOSE US?</h2>
                <h6 className="faq_text">
                  At UBK INFOTECH PVT LTD, we keep customer satisfaction at the
                  center. With our affordable and reliable services, you
                  wouldn't have to go looking anywhere else!
                </h6>
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel2a-header"
                    >
                      <Typography>Why An FAQ Resource?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        We have built all our infrastructure on most popular and
                        reliable cloud providers such as Amazon AWS, Google
                        Cloud, Microsoft Azure, Linode and many more! So, expect
                        top-notch performance that you won't get at other
                        "cheap" hosting provider.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Why FAQ Pages Are A Priority ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        We work harder to support your online projects 24x7. Our
                        support heroes are available 24x7 online to help you
                        with any problem you face with the hosting service.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="box_shadow">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>What should a FAQ contain?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        From Domains to hosting we have got you covered with
                        full range of service that your business will need to go
                        online.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>What can I use instead of FAQ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WebDevelopment;
