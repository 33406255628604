import React from "react";
import "./card.css";

export default function App(props) {
  return (
    <div className="card-wrapper">
      <div className="teamcol">
        <div className="teamcolinner">
          <div className="avatar">
            <img
              src={props.name.img}
              alt={props.name.title}
            />
          </div>
          <div className="member-name">
            
            <h6  className="app_development_txt">{props.name.title}</h6>
          </div>
          <div className="member-info">
            <p align="center" style={{fontWeight:200, fontSize:"15px"}}>
              {props.name.content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
