import React, { useState } from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
const Portfolio = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="media_query">
      <Helmet>
        <title>Portfolio</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="portfolio_bannersc">
        <div className="container">
          <Box className="banner_textbox">
            <h3 className="banner_txt">Our Project</h3>
          </Box>
        </div>
        {/* <div className="conatiner">
          <Grid className="portfolio_banner_conatiner">
          <Box sx={{textAlign:'center'}}>
              <h1 className="bold_txt white_color">Our Project</h1>
              <h6 className="banner_small_text">
                We are a team of IT professionals with 7 years of combined
                experience. We deliver effective IT solutions for web design and
                development, graphics and logos, banners and brochures,
              </h6>
            </Box>
          </Grid>
        </div> */}
      </div>
      <div className="portfolio_container">
        <div className="conatiner">
          <Grid>
            <h4 className="homeservices">Some of Our Works</h4>
          </Grid>
        </div>
      </div>
      <div className="work_bg">
        <div className="work_container">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Website" {...a11yProps(0)} />
                <Tab label="Mobile App" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="project_container">
                <div className="conatiner">
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                      <img
                        src="./carwash_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <img
                        src="./HotelFrame_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <img
                        src="./carwash_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <img
                        src="./HotelFrame_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <img
                        src="./carwash_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <img
                        src="./HotelFrame_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className="portfolio_img_responsive"
                    >
                      <img
                        src="./AqualusFrame_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="project_container">
                <div className="conatiner">
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className="portfolio_img_responsive"
                    >
                      <img
                        src="./AqualusFrame_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <img
                        src="./carwash_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <img
                        src="./HotelFrame_1.webp"
                        alt="project_img"
                        className="project_img"
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </TabPanel>
          </Box>
        </div>
      </div>
      {/* <div className="portfolio_worksc">
        <div className="conatiner">
          <Grid xs={8} md={12}>
            <Box className="technology_container">
              <Slider
                value={[10, 35, 60, 90]}
                //  ValueLabelComponent={ValueLabelComponent}
                track={false}
                onClick={(e) => handleChange(e)}
                marks={marks}
              />
            </Box>
          </Grid>
        </div>
      </div> */}
      {/* <div className="project_container">
        <div className="conatiner">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} className="portfolio_img_responsive">
              <img
                src="./AqualusFrame_1.webp"
                alt="project_img"
                className="project_img"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img
                src="./carwash_1.webp"
                alt="project_img"
                className="project_img"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img
                src="./HotelFrame_1.webp"
                alt="project_img"
                className="project_img"
              />
            </Grid>
          </Grid>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Portfolio;
