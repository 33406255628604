import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ComputerIcon from "@mui/icons-material/Computer";
import CallIcon from "@mui/icons-material/Call";
import WorkIcon from "@mui/icons-material/Work";

const pages = [
  { label: "Home", url: "/", icon: <HomeIcon sx={{ fontSize: "2rem" }} /> },
  {
    label: "About",
    url: "/about",
    icon: <InfoIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    label: "Services",
    url: "/service",
    icon: <MiscellaneousServicesIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    label: "Our Project",
    url: "/portfolio",
    icon: <ComputerIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    label: "Contact",
    url: "/contact",
    icon: <CallIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    label: "Career",
    url: "/career",
    icon: <WorkIcon sx={{ fontSize: "2rem" }} />,
  },
];

const ResponsiveAppBar = () => {
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <section className="sticky_header">
      <AppBar position="static" className="gradient">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                src="./logo.webp"
                alt="UBK INFOTECH"
                className="logo_hedaer"
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleOpenNavMenu}
                onClick={handleDrawerOpen}
                color="inherit"
              >
                <MenuIcon style={{ color: "#1e5e9d", fontSize: "2rem" }} />
              </IconButton>
              <Drawer variant="persistent" anchor="left" open={open}>
                <DrawerHeader>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                  {pages.map((item, index) => (
                    <>
                      <Link
                        to={item.url}
                        key={index}
                        className="d-flex"
                        onClick={handleDrawerClose}
                      >
                        <Typography className="navmenu">{item.icon}</Typography>
                        <Typography
                          className="navmenu"
                          sx={{ fontSize: "1.5rem" }}
                        >
                          {item.label}
                        </Typography>
                      </Link>
                      <Divider />
                    </>
                  ))}
                </List>
              </Drawer>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src="./logo.webp" className="logo_hedaer" alt="logo_img" />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((item, index) => (
                <Link to={item.url} key={index}>
                  <Typography className="navmenu">{item.label}</Typography>
                </Link>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </section>
  );
};
export default ResponsiveAppBar;
