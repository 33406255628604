import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";


const Technologies = () => {
  const [state, setState] = useState(false);
  const marks = [
    { value: 10, label: "Frontend", id: 1 },
    { value: 35, label: "Backend", id: 2 },
    { value: 60, label: "Mobile App", id: 3 },
    { value: 90, label: "Database", id: 4 },
  ];

  const ValueLabelComponent = (props) => {
    const { children, open, value } = props;
    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={`${value}%`}
      >
        {children}
      </Tooltip>
    );
  };
  const handleChange = (e) => {
    setState(!state);
    // console.log("gyrtyrtyuropo");
  };

  return (
    <div className="hiring_bg">
      <div className="technology_content">
        <Box sx={{ flexGrow: 1 }}>
          <Grid xs={12} md={12} className="sevice_header_txt">
            <h3 className="homeservices">
              Get the Right Job You Deserve !!
              <br />
            </h3>
          </Grid>
          <Grid xs={12} md={12}>
            <div>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} className="hiring_section">
                  <Grid item xs={12} sm={6} md={6}>
                    <img alt="man_img" src="./hiring.png" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{ paddingLeft: "0px !important" }}
                  >
                    <Box className="hire_text">
                      <h2 sx={{ fontSize: 14 }} className="hire_header">
                        Get Matched The Most Valuable Jobs,
                        <br /> Contact Us Now .
                      </h2>
                      <h6 className="hire_details">
                        We are a team of IT professionals with 7 years of
                        combined experience. We deliver effective IT solutions
                        for web design and development, graphics and logos,
                        banners and brochures, E-commerce, and digital
                        marketing. We deliver effective IT solutions for web
                        design and development, graphics and logos, banners and
                        brochures, E-commerce, and digital marketing.
                      </h6>
                      <Link to="/career">
                        <button className="hire_button" size="small">
                          View Details
                        </button>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Technologies;
