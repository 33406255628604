import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PlaceIcon from "@mui/icons-material/Place";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <div className="footer_sc">
      <div className="container">
        <Box sx={{ flexGrow: 1 }} className="footer_padding">
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Box sx={{ width: 1 }}>
                <img className="footer_logo" src="./logo.webp" alt="logo_img" />
                <h4 className="about_txt">About</h4>
                <div className="footer_line"></div>
                <p className="footer_paragraph">
                  We offer custom IT solutions that bring success to the table.
                  Being client-centric, quality-focused and result-oriented, we
                  strive toward exceeding customer expectations every time.
                </p>
                <Box sx={{ display: "flex" }} alignItems="center">
                  <PlaceIcon style={{ fill: "#3782B4" }} />
                  <Link>
                    <h6 className="icon_text" style={{ paddingLeft: "5px" }}>
                      {" "}
                      Shree Krishna Building 2nd Floor, Naipukur Rajarhat,
                      700135
                    </h6>
                  </Link>
                </Box>

                <Box sx={{ display: "flex" }} alignItems="center">
                  <LocalPhoneIcon style={{ fill: "#3782B4" }} />
                  <Link>
                    <h6 className="icon_text">
                      <div
                        style={{
                          margin: "4px",
                          padding: "2px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <a href="tel:8617758895">+91 8617758895</a>
                        <a href="tel:6200938310">+91 6200938310</a>
                      </div>
                    </h6>
                  </Link>
                </Box>
                <Box sx={{ display: "flex" }} alignItems="center">
                  <EmailIcon style={{ fill: "#3782B4" }} />
                  <Link>
                    <h6 className="icon_text" style={{ paddingLeft: "5px" }}>
                      <a href="mailto:info@ubkinfotech.com">
                        info@ubkinfotech.com
                      </a>
                    </h6>
                  </Link>
                </Box>
                {/* <Box sx={{ display: "flex" }} alignItems="center">
                  <EmailIcon style={{ fill: "#3782B4" }} />
                  <Link >
                    <h6 className="icon_text"> uttam.kumar233 </h6>
                  </Link>
                </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={2.5}>
              <h4 className="about_txt">Link</h4>
              <div className="footer_line"></div>
              <Box sx={{ width: 1 }}>
                <Link to="/about">
                  <h6 className="icon_text_link">About</h6>
                </Link>
                <Link to="/service">
                  <h6 className="icon_text_link">Services</h6>
                </Link>
                <Link to="/portfolio">
                  <h6 className="icon_text_link">Our Project</h6>
                </Link>
                {/* <Link to="/">
                  <h6 className="icon_text_link">Make Payment</h6>
                </Link>
                <Link to="/">
                  <h6 className="icon_text_link">Partner with us</h6>
                </Link> */}
                <Link to="/terms">
                  <h6 className="icon_text_link">Terms-conditions</h6>
                </Link>
                <Link to="/privacy">
                  <h6 className="icon_text_link">Privacy Policy</h6>
                </Link>
                <Link to="/cancellation">
                  <h6 className="icon_text_link">Cancellation and refund</h6>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={4.5}>
              <h4 className="about_txt">Social Link</h4>
              <div className="footer_line"></div>
              <Box sx={{ display: "flex" }} className="link_box">
                <div className="round_container">
                  <a href="https://www.facebook.com/UBKINFO/">
                    <FacebookIcon style={{ fill: "#fff" }} />
                  </a>
                </div>

                <div className="round_container">
                  <a href="https://wa.me/6200938310">
                    <WhatsAppIcon style={{ fill: "#fff" }} />
                  </a>
                </div>

                <div className="round_container">
                  <a href="https://www.instagram.com/ubkinfotech/">
                    <InstagramIcon style={{ fill: "#fff" }} />
                  </a>
                </div>

                <div className="round_container">
                  <a href="https://www.linkedin.com/company/ubkinfotech/">
                    <LinkedInIcon style={{ fill: "#fff" }} />
                  </a>
                </div>
              </Box>
              <iframe
                style={{ borderRadius: "5px" }}
                id="iframeid"
                title="Address"
                className="iframe_content"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58925.28624859005!2d88.414983!3d22.622808!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7aaa37002c28b6e3!2sUBK%20INFOTECH%20Pvt%20Ltd-%20No%201%20Web%20Designing%2C%20App%20Development%20%26%20Digital%20Marketing%20Company%20In%20Kolkata%2CDelhi%2C%20India!5e0!3m2!1sen!2sin!4v1653638384865!5m2!1sen!2sin"
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Footer;
