import React from "react";
import Card from 'react-bootstrap/Card';

function Tab(props) {
  const technology = props.tech.data;
  console.log("NILESH", technology);
  return (
    <div className="container" style={{display:"flex", justifyContent:"space-around"}}>
      {technology.map((tech) => (
      <Card style={{ width: "4rem", height:"8rem",display: "inline-block", border: "none"}}>
        <Card.Img variant="top" src={tech.img} alt={tech.name} style={{width:'4rem', height:'4rem', display:"flex", alignItems:"center", justifyContent:"center", margin: "0px auto",
    textAlign: "center"}}/>
        <Card.Body>
          <Card.Title style={{fontWeight:"300", fontSize:"1rem", display:"flex", alignItems:"center", justifyContent:"center"}}>{tech.name}</Card.Title>
        </Card.Body>
      </Card> ))}
    </div>
  );
}

function Navigation(props) {
  return (
    <ul className={`tabs__nav`}>
      {props.tech.map((item) => (
        <li key={item.id} className={`tabs__item`}>
          <button
            className={`tabs__button ${
              props.activeTabId === item.id ? "active" : ""
            }`}
            onClick={() => props.onNavClick(item.id)}
          >
            {item.name}
          </button>
        </li>
      ))}
    </ul>
  );
}

function Tabs(props) {
  const [activeTabId, setActiveTab] = React.useState(props.tech[0].id);

  const activeTab = React.useMemo(
    () => props.tech.find((index) => index.id === activeTabId),
    [activeTabId, props.tech]
  );

  // For Changing Nav Selected Content

  let id = 0;

  setInterval(() => {
    id = (id+1)%4;
    setActiveTab(id);
  }, 50000);

  return (
    <div className={`tabs`}>
      <Navigation
        tech={props.tech}
        onNavClick={setActiveTab}
        activeTabId={activeTabId}
      />
      <Tab tech={activeTab} />
    </div>
  );
}

export default Tabs;
