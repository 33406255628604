import React from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import Link from "@mui/material/Link";

const About = () => {
  return (
    <div className="media_query">

      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="about_bannersc">
        <div className="container">
          <Box className="banner_textbox">
            <h3 className="banner_txt"> About Us</h3>
          </Box>
        </div>
      </div>
      <div className="about_bg">
        <div className="about_detail_container">
          <div className="container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box>
                  <h6 className="about_text">
                    UBK INFOTECH PVT LTD is the most recomended Web and App
                    Development Company in india. We specialize in Web
                    Development, Android , IOS Mobile Apps and Game Development
                    we believe the Quality of services is very important to
                    quality testing of product before delivery to you.
                    <br />
                    <br />
                    Our aims to understand the customer need and providing the
                    best services to our clients Our team tries to deliver
                    high-quality product that will benefit the client's
                    business.
                  </h6>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <img
                  style={{ width: "100%" }}
                  src="./Group 24.webp"
                  alt="desktop_img"
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="about_goal_container">
        <div className="sub_container" style={{    marginLeft: "3.6rem"
}}> 
          <div className="container">
            <Box sx={{ display: { xs: "block", md: "flex", sm: "flex" } }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4} sm={4}>
                  <Box className="service_content_box">
                      <Box sx={{ display: "flex" }}>
                        <Box className="icon_box">
                          <img
                            className="icon"
                            src="./aim.jpg"
                            alt="app_img"
                          />
                        </Box>
                        <Box>
                          <h5 className="app_development_txt">Our Aim</h5>
                        </Box>
                      </Box>
                    <p className="app_development_paragrah small_font">
                      The contribution of mobile apps in this modern era, from
                      personal usage to business perspective, cannot be
                      overstated. In fact, mobile apps have become the go-to
                      platform for industries looking to increase conversion and
                      ROI.For the most effective development of modern and
                      visually appealing mobile apps, UBK INFOTECH PVT LTD is
                      one such well-known and tech-stack mobile app development
                      company in Kolkata.
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} sm={4}>
                  <Box className="service_content_box">
                      <Box sx={{ display: "flex" }}>
                        <Box className="icon_box">
                          <img
                            className="icon"
                            src="./vission.jpg"
                            alt="web_img"
                          />
                        </Box>
                        <Box>
                          <h5 className="app_development_txt"> Our Vision</h5>
                        </Box>
                      </Box>
                    <p className="app_development_paragrah small_font">
                      We need to know everything about your company when
                      creating a custom website design for you. We don't write a
                      single line of code until we've mocked up the ideal layout
                      and structure that both of us agree, this ensures you'll
                      love your new website when it's complete.UBK INFOTECH PVT
                      LTD is one such well-known and tech-stack web development
                      company in Kolkata.
                    </p>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4} sm={4}>
                  <Box className="service_content_box">
                      <Box sx={{ display: "flex" }}>
                        <Box className="icon_box">
                          <img className="icon" src="./uiux.webp" alt="" />
                        </Box>
                        <Box>
                          <h5 className="app_development_txt">
                            {" "}
                            Our Responsibility
                          </h5>
                        </Box>
                      </Box>
                    <p className="app_development_paragrah small_font">
                      If you want to keep your visitors on your website or app
                      and keep them coming back for more, it's time to think
                      outside the box. Our talented designers are experts at
                      delivering out-of-the-box solutions that ensure the
                      achievement of your goal. We strive to create designs for
                      all humans that are logical, intuitive, and visually
                      appealing.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
