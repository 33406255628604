import React from "react";
import Topheader from "../components/Topheader";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import Link from "@mui/material/Link";

const OurService = () => {
  return (
    <div className="media_query">
      <Helmet>
        <title>Our Services</title>
        <meta
          name="description"
          content="Mobile App development company in kolkata.Web Development Company in kolkata.App developers in kolkata"
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company in kolkata,outsourced web development company,website designing company in kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        />
        <meta
          name="keywords"
          content="Mobile App Design company in kolkata,Android and IOS Mobile Apps Development company in Kolkata,web design in kolkata, website designer, freelancer, logo design, templates, forum skins, open source, php scripts,  joomla, e-Commerce website, wordpress,magento,ecommerce, Paypal, payment gateway, web hosting, seo services,seo company,outsourced web development,website design company in kolkata, web design company in Kolkata, web development company in kolkata, website development company in Kolkata Mobile App Design, Mobile App Development, "
        ></meta>
      </Helmet>
      <Topheader />
      <ResponsiveAppBar />
      <div className="services_bannersc">
        <div className="container">
          <Box className="banner_textbox">
            <h3 className="banner_txt">Our Services</h3>
          </Box>
        </div>
      </div>
      <div className="ourservice_bg">
      <div className="services_content">
      <div className="container">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/web">
                <Box className="services_box">
                  <h4 className="service_header_txt">Web development</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./10.webp"
                      alt="webDevelop_img"
                    />
                  </div>
                  <div className="pt-4">
                    <h6 className="services_text">
                      The contribution of mobile apps in this modern era, from
                      personal usage to business perspective, cannot be
                      overstated. In fact, mobile apps have become the go-to
                      platform for industries looking to increase conversion and
                      ROI.For the most effective development of modern and
                      visually appealing mobile apps, UBK INFOTECH PVT LTD is one such
                      well-known and tech-stack mobile app development company
                      in Kolkata.
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/app">
                <Box className="services_box">
                  <h4 className="service_header_txt">app development</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./app.webp"
                      alt="app_img"
                    />
                  </div>
                  <div className="pt-4">
                    <h6 className="services_text">
                      We need to know everything about your company when
                      creating a custom website design for you. We don't write a
                      single line of code until we've mocked up the ideal layout
                      and structure that both of us agree, this ensures you'll
                      love your new website when it's complete.UBK INFOTECH PVT LTD is
                      one such well-known and tech-stack web development company
                      in Kolkata.
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/design">
                <Box className="services_box">
                  <h4 className="service_header_txt">Ui/ux design</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./8.webp"
                      alt="appDevelop_img"
                    />
                  </div>

                  <div className="pt-4">
                    <h6 className="services_text">
                      If you want to keep your visitors on your website or app
                      and keep them coming back for more, it's time to think
                      outside the box. Our talented designers are experts at
                      delivering out-of-the-box solutions that ensure the
                      achievement of your goal. We strive to create designs for
                      all humans that are logical, intuitive, and visually
                      appealing.
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/machine_learning">
                <Box className="services_box">
                  <h4 className="service_header_txt">machine learning</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./6.webp"
                      alt="logoDesign_img"
                    />
                  </div>
                  <div className="pt-4">
                    <h6 className="services_text">
                      Create adaptive models for object classification and
                      predictive analytics directly from complicated datasets,
                      such as determining which new advertising markets to
                      enter. UBK INFOTECH PVT LTD provides machine learning solution in
                      Kolkata using Python from data scientists. Full-scale
                      project execution and tool development in Data Analytics,
                      Machine Learning, and Deep Learning. Scale Up your
                      bussiness using Machine Learning
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/iot">
                <Box className="services_box">
                  <h4 className="service_header_txt">iot</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./4.webp"
                      alt="email_img"
                    />
                  </div>

                  <div className="pt-4">
                    <h6 className="services_text">
                      IoT development entails mixing hardware components and
                      software programmes so that the finished product may
                      monitor certain values, gather and send data, evaluate
                      given data, and cause the physical device to act
                      accordingly. Developing such systems is a significant
                      problem.UBK INFOTECH PVT LTD is an IoT development company that
                      helps new businesses and enterprises throughout the globe
                      build sophisticated IoT apps .
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/software">
                <Box className="services_box">
                  <h4 className="service_header_txt">software solutions</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./software_sol.webp"
                      alt="domainHosting_img"
                    />
                  </div>
                  <div className="pt-4">
                    <h6 className="services_text">
                    UBK INFOTECH PVT LTD  is a software development company that offers
                      industry-specific software solutions to enterprises all
                      around the world. We specialise in providing software
                      development solutions. Net Solutions allows you to create
                      world-class software. Our skilled developers work with
                      your company to increase income and growth. We prioritise
                      your requirements and provide high-quality software
                      solutions based on our experience and skills.
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/marketing">
                <Box className="services_box">
                  <h4 className="service_header_txt">digital marketing</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./digital_img.webp"
                      alt="logoDesign_img"
                    />
                  </div>
                  <div className="pt-4">
                    <h6 className="services_text">
                      We aim to produce beneficial leads that will take your
                      company to new heights. Every time, we go above and above
                      to make you a dominant brand. You can fully focus on your
                      main company with a result-driven digital marketing
                      approach. Let us work together to bring about digital
                      change. Use the power of digital marketing to achieve your
                      goals.
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/branding">
                <Box className="services_box">
                  <h4 className="service_header_txt">branding solutions</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./5.webp"
                      alt="email_img"
                    />
                  </div>
                  <div className="pt-4">
                    <h6 className="services_text">
                      Our corporate branding solutions create a distinct
                      identity for your brand, giving you a competitive
                      advantage. Imagine having access to the world's most
                      powerful and valuable brands' secret brand strategy for
                      generating long-term profitable growth.UBK INFOTECH PVT LTD Infotech has
                      the branding and marketing knowledge you require to be
                      successful.We are one of the best creative advertising and
                      branding agencies in Kolkata
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: "2%" }}>
              <Link href="/testing">
                <Box className="services_box">
                  <h4 className="service_header_txt">software testing</h4>
                  <div className="service_img_box">
                    <img
                      style={{ width: "100%" }}
                      src="./3 .webp"
                      alt="domainHosting_img"
                    />
                  </div>
                  <div className="pt-4">
                    <h6 className="services_text">
                      Get software testing services in Kolkata because we
                      deliver custom, results-driven campaigns that help our
                      clients. Full-cycle testing services. QA Managed; QA
                      consulting ; Systems & platforms. CRM testing; IoT testing
                      ; Software lifecycle. QA Managed; QA Test automation .
                      Having 7 years of expertise in offering software testing
                      services, we seek to deliver high-quality testing
                      solutions to strive for a marked digital experience.
                    </h6>
                  </div>
                </Box>
              </Link>
            </Grid>
          </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurService;
